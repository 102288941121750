export const INSPECTION_ERRORS = {
  FAILED_TO_LOAD_LINE: "Failed to load inspection line, please try again.",
  FAILED_TO_UPDATE_CAT_1: "Failed to update CAT 1 results, please try again.",
  FAILED_TO_UPDATE_PERIODIC:
    "Failed to update Periodic results, please try again.",
  FAILED_TO_UPDATE_CAT_5: "Failed to update CAT 5 results, please try again.",
};

export const INSPECTION_SUCCESS = {
  UPDATED_CAT_1: "Updated CAT 1 results!",
  UPDATED_CAT_1_FIRE:"Updated Fire Service Results!",
  UPDATED_PERIODIC: "Updated Periodic results!",
  UPDATED_CAT_5: "Updated CAT 5 results!",
};

export const INSPECTION_CONFIRMATION = {
  REMOVE_ALL_DEFS_CONFIRMATION:
    "This will remove all defs for this device. Continue?",
};

export const INSPECTION_VALIDATIONS = {
  MISSING_INSPECTION_LINE: "Line is missing, can't validate.",
  CAT_RESULTS_MISMATCH: "CAT results don't match.",
  DEFS_REQUIRED_WHEN_UNSATISFACTORY: "At least one deficiency is required",
  DEFS_MUST_BE_EMPTY_WHEN_SATISFACTORY:
    "Can't have deficiencies when Inspection result is Satisfactory",
  INSPECTOR_REQUIRED: "Inspector is required",
  MISSING_CAT_RESULT: "Inspection results can not be N/A",
  UNSATISFACTORY_CAT_RESULT_NOT_ALLOWED:
    "Inspection results must be Satisfactory.",
};
